<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row title-row justify-content-between">
              <div class="col-xxl-8 col-xl-8 col-lg-7 col-md-9">
                <h4 class="card-title mb-4">
                  User Staking List ({{ (stake_data) ? stake_data.stake_currency +'-'+ stake_data.reward_currency : ''}})
                  <br />
                </h4>
                <table class="table table-borderless mb-2">
                  <tbody>
                    <tr>
                      <td class="ps-0">
                        <input
                          type="text"
                          v-model="filters.name"
                          @keydown.enter="topFilter(filters.name, 'name')"
                          class="p-column-filter"
                          placeholder="Search by Name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="filters.amount"
                          @keydown.enter="topFilter(filters.amount, 'amount')"
                          class="p-column-filter"
                          placeholder="Search by amount"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          v-model="filters.created_at"
                          dateFormat="dd-mm-yy"
                          class="p-column-filter"
                          @clear="filters.created_at = ''"
                          @change="topFilter(filters.created_at, 'created_at')"
                          placeholder="Created At"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="btn-box">
                  <button
                    class="btn btn-primary"
                    @click="filters.name= '', filters.amount= '', filters.created_at= ''"
                  >Clear</button>
                </div>-->
              </div>
              <div class="col-xxl-3 col-xl-3 col-lg-4 col-md-3">
                <div class="text-end border rounded-2">
                  <h4 class="card-title p-2">
                    <p class="mb-2">Total Stake : {{ fixafterPoint(sum) }}</p>
                    <p class="mb-2">Total User : {{ totalRows }}</p>
                    <p class="mb-0">Total Interest : {{ interest }}</p>
                  </h4>
                </div>
              </div>
            </div>
            <!-- <svg style="width:24px;height:24px" viewBox="0 0 24 24" @click="showFilter = !showFilter">
          <path
            fill="currentColor"
            d="M3.47 5C3.25 5 3.04 5.08 2.87 5.21C2.43 5.55 2.35 6.18 2.69 6.61L2.69 6.62L7 12.14V18.05L10.64 21.71C11 22.1 11.66 22.1 12.05 21.71L12.05 21.71C12.44 21.32 12.44 20.69 12.06 20.3L9 17.22V11.45L4.27 5.39C4.08 5.14 3.78 5 3.47 5M21.62 3.22C21.43 3.08 21.22 3 21 3H7C6.78 3 6.57 3.08 6.38 3.22C5.95 3.56 5.87 4.19 6.21 4.62L11 10.75V15.87C10.96 16.16 11.06 16.47 11.29 16.7L15.3 20.71C15.69 21.1 16.32 21.1 16.71 20.71C16.94 20.5 17.04 20.18 17 19.88V10.75L21.79 4.62C22.13 4.19 22.05 3.56 21.62 3.22M15 10.05V17.58L13 15.58V10.06L9.04 5H18.96L15 10.05Z"
          />
            </svg>-->

            <div class="row filter-row align-items-center mb-4">
              <div class="col-xl-6">
                <!-- <table class="table table-borderless mb-2">
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          v-model="filters.name"
                          @keydown.enter="topFilter(filters.name, 'name')"
                          class="p-column-filter"
                          placeholder="Search by Name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          v-model="filters.amount"
                          @keydown.enter="topFilter(filters.amount, 'amount')"
                          class="p-column-filter"
                          placeholder="Search by amount"
                        />
                      </td>
                      <td>
                        <input
                          type="date"
                          v-model="filters.created_at"
                          dateFormat="dd-mm-yy"
                          class="p-column-filter"
                          @clear="filters.created_at = ''"
                          @change="topFilter(filters.created_at, 'created_at')"
                          placeholder="Created At"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>-->
              </div>

              <!-- <div class="col-xl-6">
                <div class="btn-box ">
      
                  <button class="btn btn-primary" @click="filters.name= '', filters.amount= '', filters.created_at= ''">Clear</button>
                </div>
              </div>-->
            </div>

            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <!-- <b-input v-model="filter" placeholder="Filter table.."></b-input>   :filter="filter"
                :filter-function="myProvider"-->
                <!-- <hr /> -->

                <b-table
                  :items="items1"
                  :fields="fields"
                  responsive="sm"
                  :per-page="0"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <!-- <template :slot="`HEAD_${ key.key }`" v-for="key in fields" slot-scope="data">
                    <div :key="key">
                      <b>{{ data.key }}</b>
                      <b-input v-model="filter"
                      v-if="data.key == 'name'" placeholder="Filter table.."></b-input>
                    </div>
                  </template>-->
                  <!-- <b-thead>
                    <b-tr>
                      <b-th sticky-column>Sticky Column Header</b-th>
                      <b-th>Heading 1</b-th>
                      <b-th>Heading 2</b-th>
                      <b-th>Heading 3</b-th>
                      <b-th>Heading 4</b-th>
                    </b-tr>
                  </b-thead>-->
                  <template #empty>
                    <div class="d-flex justify-content-center">No Record Found</div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <!-- <template #cell(SR_NO)="data">{{ data.index + 1 }}</template> -->
                  <template #cell(S_Id)="data">PXCUSRSTK#{{ data.item.id }}</template>

                  <template v-slot:cell(user_name)="data">
                    <!-- <div v-if="data.item.user !== null">
                      {{ data.item.user.name }} 
                    </div>-->
                    <router-link
                      :to="'/users/list/' + data.item.user_id"
                      class="text-dark"
                    >{{ data.item.user.name }}{{data.item.user_id}}</router-link>
                  </template>

                  <!-- <template v-slot:cell(description)="data">
                    <div
                      v-if="data.item.staking_plan!==null"
                    >{{ data.item.staking_plan.description }}</div>
                    <div v-else>N/A</div>
                  </template>-->

                  <!-- <template v-slot:cell(stake_currency)="data">
                    <div
                      v-if="data.item.staking_plan!==null"
                    >{{ data.item.staking_plan.stake_currency }}</div>
                    <div v-else>N/A</div>
                  </template>-->

                  <!-- <template v-slot:cell(reward_currency)="data">
                    <div v-if="data.item.reward_currency">{{ data.item.reward_currency }}</div>
                  </template>-->
                  <template v-slot:cell(email)="data">
                    <div v-if="data.item.user.email">{{ data.item.user.email }}</div>
                  </template>
                  <template v-slot:cell(amount)="data">
                    <div
                      v-if="data.item.amount"
                      class="text-capitalize"
                    >{{ parseFloat(data.item.amount) }}</div>
                  </template>
                  <template v-slot:cell(autostake_enable)="data">
                    <div>{{ (data.item.autostake_enable) ? 'Active' : 'Inactive' }}</div>
                  </template>
                  <template v-slot:cell(next_roi_date)="data">
                    <div v-if="data.item.next_roi_date">{{ add2days(data.item.next_roi_date) }}</div>
                  </template>
                  <template v-slot:cell(roi_income)="data">
                    <div
                      v-if="data.item.roi_income"
                    >{{ data.item.roi_income ? (parseFloat(data.item.roi_income).toFixed(10)) :''}}</div>
                  </template>

                  <template v-slot:cell(created_at)="data">
                    <div
                      v-if="data.item.created_at"
                    >{{ new Date(data.item.created_at).toLocaleDateString() + ' ' + new Date(data.item.created_at).toLocaleTimeString()}}</div>
                  </template>
                  <template v-slot:cell(expired_at)="data">
                    <div
                      v-if="data.item.expiry_date"
                    >{{ new Date(data.item.expiry_date).toLocaleDateString() + ' ' + new Date(data.item.expiry_date).toLocaleTimeString()}}</div>
                  </template>
                  <template v-slot:cell(renewal_date)="data">
                    <div v-if="data.item.expiry_date">{{ add2days(data.item.expiry_date) }}</div>
                  </template>
                  <!-- <template v-slot:cell(plan_type)="data">
                    <div
                      v-if="data.item.plan_type"
                      class="text-capitalize"
                    >{{ data.item.plan_type }}</div>
                  </template>-->
                  <!-- Status section -->
                  <template v-slot:cell(activate_status)="data">
                    <div
                      class="text-center py-1 px-3 rounded fw-bold"
                      :class="
                        data.item.is_active == 1 ? 'bg-success' : 'bg-warning'
                      "
                    >{{ data.item.is_active == 1 ? "ON" : "OFF" }}</div>
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div class="dataTables_paginate paging_simple_numbers float-end">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" @input="myProvider" :total-rows="totalRows" :per-page="perPage"></b-pagination>
                      <!-- v-model="currentPage" -->
                      <!-- :pantry-items='items1' -->
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
export default {
  page: {
    title: "User Stake List",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    simplebar
  },
  data() {
    return {
      title: "User Stake List",
      items: [
        {
          text: "Staking "
          // href: "/staking/list",
        },
        {
          text: "User Staking List",
          active: true
        }
      ],
      loading: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      sortBy: "value",
      sortDesc: false,
      fields: [
        "S_Id",
        // {
        //   key: "user_stake_id",
        //   value: "user_stake_id",
        //   sortable: false,
        //   label:"User SId"
        // },
        {
          key: "user_name",
          value: "user_name",
          sortable: false
        },

        {
          key: "email",
          value: "email",
          sortable: false
        },
        {
          key: "amount",
          value: "amount",
          sortable: false
        },
        // {
        //   key: "stake_currency",
        //   value: "stake_currency",
        //   sortable: false
        // },
        // {
        //   key: "reward_currency",
        //   value: "reward_currency",
        //   sortable: false
        // },
        {
          key: "autostake_enable",

          value: "autostake_enable",
          sortable: false
        },
        {
          key: "next_roi_date",
          value: "next_roi_date",
          sortable: false
        },
        {
          key: "roi_income",
          value: "roi_income",
          sortable: false
        },

        // {
        //   key: "description",
        //   value: "description",
        //   sortable: false
        // },
        {
          key: "created_at",
          value: "created_at",
          label: "Subscription Date",
          sortable: false
        },
        {
          key: "expired_at",
          label: "Interest End Date",
          value: "expired_at",
          sortable: false
        },
        {
          key: "renewal_date",
          label: "Reward/Renew Date",
          value: "expired_at",
          sortable: false
        },

        // {
        //   key: "plan_type",
        //   value: "plan_type",
        //   sortable: false
        // },
        {
          key: "activate_status",
          value: "activate_status",
          sortable: false
        }
        // {
        //     key: "action",
        //     value: "action",
        //     sortable: false,
        // },
      ],
      plan_id: "",
      items1: [],
      sum: 0,
      filter: "",
      filters: { name: "", amount: "", created_at: "" },
      interest: 0,
      showFilter: false,
      stake_data: ""
    };
  },
  watch: {
    currentPage: function() {
      this.myProvider();
    }
  },
  mounted() {
    this.plan_id = this.$route.params.slug ?? 1;
    this.myProvider();
    console.log(this.$route.params.slug);
  },
  methods: {
    fixafterPoint(value, decimal = 8) {
      // console.log(value);
      value = value.toString();
      if (value && value.includes(".")) {
        let decimal_split = value.split(".");
        let final = decimal_split[0] + "." + decimal_split[1].slice(0, decimal);
        return final;
      }
      return value;
    },
    add2days(date) {
      // console.log({ date });
      var result = new Date(date);
      result.setDate(result.getDate() + 2);

      // console.log({dttt:new Date(result).toLocaleDateString()});
      return new Date(result).toLocaleDateString();
      // D.setUTCDate(D.getUTCDate() +2)
      // return new Date(result)
      // return D.getDate().setDate(D.getDate() + 2);

      // return date;
    },
    async myProvider() {
      // this.loading = !this.loading;
      this.loading = true;
      // console.log({ filquery: this.filter });

      await ApiClass.getNodeRequest(
        "staking/get_by_plan_id/" +
          this.plan_id +
          "?per_page=" +
          this.perPage +
          "&page=" +
          this.currentPage +
          "&" +
          this.filter,
        true
      ).then(res => {
        if (res.data.status_code == 1) {
          this.loading = false;
          let result = res.data.data;
          this.items1 = result ? result.data.data : [];
          this.currentPage = result ? result.data.current_page : "";
          this.totalRows = result ? result.data.total : "";
          this.stake_data = result ? result.staking_plan : "";
          // this.stake_data = result.data.staking_plan;
          this.interest = result ? result.total_interest : 0;
          this.sum = result.sum;
          // callback(items);
        }
      });
      // .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
    topFilter(columnVal, columnName) {
      console.log({ columnVal });
      // if (columnName == "created_at") {
      //   columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
      //   columnVal = columnVal.replaceAll("/", "-");

      //   columnVal = columnVal
      //     .split("-")
      //     .reverse()
      //     .join("-");
      // }
      // if (columnName == "dob") {
      //   columnVal = columnVal.toLocaleDateString("es-US").split("T")[0];
      //   columnVal = columnVal.replaceAll("/", "-");

      //   columnVal = columnVal
      //     .split("-")
      //     .reverse()
      //     .join("-");
      // }
      this.loading = !this.loading;

      this.$set(this.filters, columnName, columnVal);

      var x = this.filters;
      const resulted = JSON.parse(JSON.stringify(x), (key, value) =>
        value === null || value === "" ? undefined : value
      );

      let query = new URLSearchParams(resulted).toString();

      this.filter = query;
      this.myProvider();
    },
    userData(id) {
      this.$router.push("/user/" + id);
    }
  }
};
</script>

<style scoped>
.p-column-filter {
  font-size: 13px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ccc;
  width: 100%;
  padding: 7px 10px;
  border-radius: 2x;
}
</style>
